.guide {
  margin: 0 0 80px;

  &__container {
    max-width: 100%;
    // overflow: hidden; // hides shadows
  }

  &__sidebar {
    margin: 0 0 40px;
  }

  &__title {
    font-family: Spartan;
    font-size: 44px;
    font-weight: 600;
    line-height: 51px;
    margin: 0 0 20px;
  }

  &__menu.menu.ui.vertical {
    margin: 0 0 1rem;
    background: transparent;
    border: none;

    .item {
      padding: 21px 20px 19px;
      border-radius: 10px !important;
      color: #262626;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 10px;

      &.active {
        background: #01a0ac;

        border-color: transparent;
        color: white;
      }
    }
  }

  &__pdf {
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: relative;
    min-height: 300px;
    user-select: none;
    // max-width: 100%;
  }

  &__pdf-actions {
    position: absolute;
    left: 30px;
    top: 30px;

    .ui.button {
      margin-right: 5px;
      height: 30px;
      padding: 5px 18px;
    }
  }

  &__pagination {
    margin-top: 16px;
  }

  &__pdf &__pagination {
    position: absolute;
    left: 20px;
    bottom: 20px;
    right: 20px;
  }

  &__links {
    margin-top: 40px;
  }

  &__zoom {
    position: absolute;
    right: 30px;
    bottom: 30px;
  }

  @media (min-width: 1200px) {
    display: flex;

    &__sidebar {
      flex: 0 0 320px;
      margin: 0 80px 0 0;
    }

    &__container {
      flex: 1 0 0;
    }
  }
}

.react-pdf__Document,
.react-pdf__Page,
.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}
.react-pdf__Page__canvas {
  object-fit: contain;
}
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}