.reflection-view {
  &__group {
    counter-reset: reflection;
  }

  &__item {
    counter-increment: reflection;

    & + & {
      padding-top: 36px;
      border-top: 2px solid #f4f4f4;
    }

    &--textbox {
      margin-bottom: 36px;

      .modal & {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    content: counter(reflection) ". ";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px;
  }

  &__boolean,
  &__text-list,
  &__list {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6a6a6a;
    list-style: none;
    padding: 0;
    margin: 0 0 36px;

    li {
      margin: 0 0 8px;
    }

    .badge {
      margin-left: 5px;
    }
  }

  &__text-list {
    margin: 0;
    list-style: inside disc;

    li {
      margin-bottom: 20px;
    }

    strong {
      &::after {
        content: ": ";
        font-weight: normal;
      }
    }

    p {
      display: inline;
      margin: 0;
    }
  }

  &__points {
    margin: 22px 0 36px;
    padding: 0;
    list-style: none;
    font-size: 16px;
    line-height: 24px;
    color: #6a6a6a;

    li {
      white-space: nowrap;
      display: inline-block;
      padding-right: 54px;
      margin-right: 20px;
      position: relative;

      &::after {
        content: " ";
        width: 34px;
        height: 1px;
        right: 0;
        top: 12px;
        background: #e1e1e1;
        position: absolute;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;
        &::after {
          content: none;
        }
      }
    }

    strong {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      color: #00a0af;
      margin-right: 2px;
    }

    .badge {
      vertical-align: top;
    }
  }

  &__boolean {
    padding: 0;
    margin: 0 0 36px;

    strong {
      color: #00a0af;
      position: relative;
      display: inline-block;
      padding-left: 44px;
      margin-left: 10px;
      font-weight: 500;

      &::before {
        content: " ";
        width: 34px;
        height: 1px;
        left: 0;
        top: 12px;
        background: #e1e1e1;
        position: absolute;
      }
    }
  }
}

.reflection-preview {
  & + & {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 2px solid #f4f4f4;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px;
  }

  &__answer {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6a6a6a;

    p {
      margin: 0;
    }
  }

  &__point {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    color: #00a0af;
    display: inline-block;
    // display: inline-block;
    // border: 1px solid #ccc;
    // min-width: 40px;
    // height: 40px;
    // padding: 8px 5px 2px;
    // text-align: center;
    // border-radius: 3px;
  }
}
