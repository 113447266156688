.shadowing-metadata {
  color: @primaryColor;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 16px;
  margin-right: -30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  span {
    margin-right: 29px;
    margin-top: 3px;
  }

  img {
    margin-right: 6px;
    vertical-align: top;
  }
}
