@import "../styles/components/styles.less";
@import "../components/styles/sh-icon.less";
@import "../components/styles/error-message.less";
@import "../components/styles/text-block.less";
@import "../components/styles/page-title.less";
@import "../components/styles/page-photos.less";
@import "../components/styles/shadowing-metadata.less";
@import "../features/home-page/styles.less";

body {
  background: #fff;

  @media (min-width: 840px) {
    background: url(../../public/assets/bg.png) #fff center 0 no-repeat;
  }

  @media (min-width: 1200px) {
    &::before,
    &::after {
      position: absolute;
      z-index: 10;
      left: 50%;
      top: 408px;
    }

    &::before {
      content: url(../../public/assets/burbulas-left.png);
      margin-left: -774px;
    }

    &::after {
      content: url(../../public/assets/burbulas-right.png);
      margin-top: -8px;
      margin-left: 445px;
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > .container {
    flex: 1 0 auto;
    position: relative;
    z-index: 11; // burbulai = 10
  }
}
