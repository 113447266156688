body {
  .ui.top.menu {
    height: 90px;
    padding: 0 1rem;
    background-color: #fff;
    transition:
      height 0.5s ease,
      margin 0.5s ease;
    border: 0 none;

    &.is-scrolled {
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    }

    &.is-hidden {
      margin-top: -70px;
    }

    &.is-scrolled,
    &.is-hidden {
      height: 70px;

      .sidenav-trigger {
        top: 13px;
      }
    }

    .logo {
      img {
        width: 214.667px;
        height: 50.667px;
      }
    }

    .right.menu {
      display: none;
    }

    .sidenav-trigger {
      border: 0 none;
      padding: 0;
      color: #000;
      background: transparent;
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 23px;
      width: 44px;
      height: 44px;
      padding: 12px 3px;
      transition: top 0.5s ease;

      .icon {
        width: 38px;
        height: 20px;
      }
    }

    @media (min-width: 768px) {
      padding: 0 34px;
    }

    @media (min-width: 992px) {
      .right.menu {
        display: flex;
      }

      .sidenav-trigger {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .sidenav-opened {
    overflow: hidden;
  }
}

.sidenav {
  &__content {
    position: fixed;
    left: 100%;
    top: 0;
    max-width: 330px;
    width: 100%;
    z-index: 1000;
    height: 100%;
    background: #fff;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  &--opened &__content {
    transform: translate(-100%, 0);
  }

  &__overlay {
    content: " ";
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transition:
      opacity 0.3s ease,
      visibility 0s ease 0.3s;
  }

  &--opened &__overlay {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  @media (min-width: 992px) {
    display: none;
  }

  &__header {
    font-family: Spartan;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    color: #262626;
    margin: 0 0 24px;
    min-height: 62px;

    padding: 22px 60px 22px 22px;
  }

  &__close {
    color: #262626;
    width: 32px;
    height: 32px;
    padding: 10px;
    border: 0 none;
    background: transparent;
    margin: 0;
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;

    .icon {
      width: 12px;
      height: 12px;
    }
  }

  .ui.vertical.menu {
    width: auto;
    box-shadow: none;
    border-left: 0 none;
    border-right: 0 none;
    border-radius: 0;
    margin: 0;

    .item {
      border-radius: 0 !important;
    }

    &.auth-menu {
      margin-top: auto;
    }
  }

  &-filter-footer {
    padding: 12px 16px;
    background: #fff;
    position: fixed;
    bottom: 0;

    & > * {
      margin-left: 20px !important;
    }
  }
}
