/*******************************
         Site Overrides
*******************************/

/*--------------
      Basic
---------------*/

.ui.divider:not(.vertical):not(.horizontal) {
  border-top: @shadowWidth dashed @shadowColor;
  border-bottom: @highlightWidth dashed @highlightColor;
}