.file-input {
  font-family: "Spartan", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  padding: 8px 0;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  &__preview {
    width: 100px;
    height: 46px;
    border-radius: 4px;
    background: center center no-repeat;
    background-size: contain;
    border: 1px solid #e8e8e8;
    margin: -8px 15px -8px 0;
  }

  &__value {
    flex: 0 1 auto;
    color: #00a0af;
    margin-right: 10px;
    max-width: 50%;
  }

  &__filename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__placeholder,
  &__value {
    &::before {
      content: " ";
      background: url(../../icons/attachment.svg) center center no-repeat;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
      width: 30px;
      height: 30px;
      text-align: center;
      float: left;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 10px;
    }
  }

  &__actions {
    flex: 0 0 auto;
    .link {
      margin-left: 10px;
      color: #6a6a6a !important;

      &:active,
      &:hover {
        color: #00a0af !important;
      }
    }
  }

  &.is-disabled &__placeholder,
  &.is-disabled &__value,
  &.is-disabled .link {
    cursor: default;
    color: #c4c4c4 !important;
    pointer-events: none;
    user-select: none;
  }
}
