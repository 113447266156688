/*******************************
        User Overrides
*******************************/

.ui.dropdown>.dropdown.icon {
    margin: 0em 0em 0em 6px;
}

.ui.selection.dropdown {
  min-height: 50px;
  padding-top: 13px;
  padding-bottom: 11px;
  min-width: 100px;
  line-height: 24px;

  > .text {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.multiple {
    padding-top: 11px;
    padding-bottom: 9px;
    > .text {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    > .label {
      text-decoration: none;
      padding-top: 7px;
      padding-bottom: 3px;
    }
  }
}

.ui.selection.dropdown > .dropdown.icon {
  top: 15px;
}
