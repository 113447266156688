/*******************************
         Site Overrides
*******************************/

.ui.header {
  font-weight: 600;
  font-size: 22px !important;
  line-height: 28px;
}

.ui.modal {
  max-width: 700px;
  padding-bottom: 30px;
}

.ui.modal > .header:not(.ui) {
  text-align: center;
}

.ui.header {
  p:first-child {
    margin-right: 10px;
  }
}

.ui.header + p {
  color: @primaryColor;
  margin-bottom: 34px;
  font-weight: 600;

  span {
    margin-right: 21px;
  }

  img {
    margin-right: 6px;
  }
}

.cv-upload {
  * {
    text-align: left;
  }
}

.ui.modal .actions {
  padding: 0 40px;
  text-align: left;
}

.ui.modal .actions > .button {
  margin: 0 10px 10px 0;
}

.ui.modal > .content {
  padding: 40px 40px 34px;
  position: relative;
  min-height: 180px;
}

.ui.modal.tiny > .content {
  min-height: 0;
}

.ui.modal > .close {
  width: 32px;
  height: 32px;
  right: 13px;
  top: 13px;
  position: absolute;
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }

  &::before,
  &::after {
    content: " ";
    background: #262626 !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: rotate(45deg);
  }

  &::after {
    width: 13px;
    height: 1px;
    margin-left: -6px;
  }

  &::before {
    width: 1px;
    height: 13px;
    margin-top: -6px;
  }
}

.ui.visible.dimmer.modals.visible {
  display: flex !important;
}