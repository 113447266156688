/*******************************
         Site Overrides
*******************************/

// PENDING REGISTRATIONS.

.ui.placeholder.segment.pending-registrations-segment {
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  border: none;
  background: @softGrey;
  margin-bottom: 70px;
}

.pending-registrations {
  margin-left: 16%;
  margin-right: 16%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pending-registrations-text {
  font-weight: 600;
  font-size: 16px;
}

.ui.placeholder.segment.full-width-grey {
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  border: none;
  background: @softGrey;
  margin-bottom: 70px;
}
