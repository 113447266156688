.button-icon {
  border: 1px solid #e9e9e9;
  border-radius: 60px;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  background: #fff;

  .icon {
    margin: 0;
    width: auto;
    height: auto;
    font-size: 0;
    fill: #000;
  }

  &:focus {
    outline: none;
    border-color: #00a0af;
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.1);
    background: #00a0af;

    .icon {
      fill: #fff;
    }
  }
}

.ui.buttons {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px;
  background: #fff;

  .dropdown,
  .button {
    background: transparent;
    color: #262626;
    padding: 12px 15px;
    line-height: 24px;
    height: 48px;
    text-align: left;
    position: relative;

    &:before {
      content: " ";
      border-left: 2px dashed #f4f4f4;
      width: 1px;
      top: 5px;
      bottom: 5px;
      left: -1px;
      position: absolute;
    }

    .icon {
      width: 24px;
      height: 24px;
      margin: 0 5px 0 0 !important;
      vertical-align: top;
      float: left;
      opacity: 1 !important;
    }

    &:hover {
      background: #f5f5f5;
    }

    &:first-child {
      border-top-left-radius: 6px !important;
      border-bottom-left-radius: 6px !important;

      &:before {
        content: none;
      }
    }

    &:last-child {
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }
  }

  .dropdown {
    display: block;

    > .text {
      font-size: 14px;
    }
  }

  .shadowing-sorter {
    width: 50%;

    .dropdown {
      border-radius: 6px 0 0 6px !important;
    }
  }
}
