.applications-filter {
  padding-top: 60px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  .ui.secondary.menu .item {
    background: transparent;
  }
}
