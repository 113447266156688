.list-filter {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  gap: 0 20px;

  &-options {
    min-width: 0;
    line-height: 2;
    padding-top: 8px;

    .ui.dropdown {
      margin-right: 27px;
    }

    &-available {
      > span:first-child {
        margin-right: 27px;
        &:last-child {
          display: none;
        }
      }
    }

    &-selected {
      margin-top: 10px;

      &:empty {
        display: none;
      }
    }
  }

  &-search {
    min-width: 0;
    margin-left: auto;
  }
}
