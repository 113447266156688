@import "~react-toastify/dist/ReactToastify.css";

.Toastify__toast {
  font-family: "Spartan", sans-serif;
  font-weight: 500;
  font-size: 14px;
  min-height: 48px;
  padding-top: 10px;
}

.Toastify__toast-icon {
  margin-top: -3px;
}

.Toastify__toast-container--top-right {
  right: 10px;
  top: 10px;
}