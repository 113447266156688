@spacing: 20px;
@spacingLg: 40px;

.homepage-two-articles {
  display: flex;
  flex-direction: column;
  gap: 36px;
  position: relative;
  z-index: 2;
  margin: -180px 0 106px;
  text-align: justify;

  @media (min-width: 992px) {
    flex-direction: row;
  }

  .article {
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: #fff;
    padding: @spacing;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
      padding: @spacingLg;
    }

    @media (min-width: 992px) {
      flex: 1 0 0;
    }

    &:first-child .article__image {
      margin-top: -2 * @spacing;

      @media (min-width: 768px) {
        margin-top: -50px - @spacingLg;
      }
    }

    &__image {
      width: 100%;
      height: auto;
      margin-bottom: @spacing;
      border-radius: 10px;

      @media (min-width: 768px) {
        margin-bottom: @spacingLg;
      }

      @media (min-width: 992px) {
        margin-top: -50px - @spacingLg;
      }
    }

    &__title-sm {
      margin-bottom: 16px;
      text-align: left;
    }

    p {
      margin-bottom: @spacing;

      @media (min-width: 768px) {
        margin-bottom: @spacingLg;
      }
    }

    .button {
      margin-top: auto;
    }
  }
}
