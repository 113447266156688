.shadowing-filter {
  &__checkbox-group {
    padding: 5px 0 0;
    max-height: 145px;
    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: #929292 #f4f4f4;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
      background: #929292 !important;
    }

    .field {
      margin-bottom: 10px !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .sidenav & {
    margin: 0 22px;
  }

  .item-filter {
    margin: 0 0 10px;
  }
}
