/*******************************
         Site Overrides
*******************************/

.ui.labels .label,
.ui.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 5px;
}

.ui.circular.label {
  line-height: 14px;
}