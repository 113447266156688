body.page-auth {
  .container {
    padding-top: 110px !important;
  }

  .footer {
    @media (max-width: 769px) {
      display: none;
    }
  }

  @media (min-width: 1200px) {
    background-image: none;

    #root > .ui.container {
      z-index: auto;
    }

    &::before,
    &::after {
      top: 90px;
      z-index: 12;
    }

    &::before {
      margin-left: -680px;
    }

    &::after {
      content: url(../../../../public/assets/burbulas-right-sm.png);
      top: 628px;
      margin-left: 510px;
      border-radius: 26px;
    }
  }
}

.auth-layout {
  padding-bottom: 80px;
  position: relative;

  &::before {
    position: absolute;
    content: url(../../../../public/assets/burbulas-left.png);
    left: -126px;
    top: -18px;
    width: 161px;
    height: 216px;
  }

  &__content {
    &::after {
      position: absolute;
      content: url(../../../../public/assets/burbulas-right-sm.png);
      top: -35px;
      left: 100%;
      margin-left: -90px;
      width: 57px;
    }
  }

  &__img {
    display: none;
  }

  &__mob-img {
    width: 100%;
    z-index: 11;
    position: relative;
    border-radius: 30px;
  }

  &__content {
    background: #fff;
    border-radius: 20px;
    margin: -50px -14px 0;
    position: relative;
    z-index: 12;
    padding: 40px 30px 20px;
  }
}

.registration-header {
  margin: 0 0 20px;

  p {
    font-size: 16px;
    color: #6a6a6a;
  }
}

.login-button {
  margin-top: 20px;
}

.registration-bottom {
  border-top: 2px solid #f4f4f4;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 14px;
  background: #fff;
}

.registration-actions {
  display: flex;
  align-items: center;
}

.registration-buttons {
  flex: 1 0 auto;

  .button {
    + .button {
      margin-left: 10px;
    }
  }
}

.registration-counter {
  height: 50px;
  width: 50px;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 50%;
  font-family: Spartan;
  color: #6a6a6a;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 48px;
}

.registration-description-step {
  &,
  .form,
  .field {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
  }

  textarea {
    flex-grow: 1;
  }
}

.page-registration {
  .registration-extra-text {
    margin-top: 24px;
    font-size: 16px;
    color: #6a6a6a;
    flex-grow: 1;
    line-height: 24px;
  }

  .registration-actions {
    margin-top: 30px;
  }
}

@media (min-width: 768px) {
  .auth-layout {
    padding-bottom: 20px;

    &__content {
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
      padding-bottom: 100px;
    }

    &::before {
      z-index: 13;
    }
  }
  .registration-bottom {
    position: absolute;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    padding: 30px 50px;
  }

  .page-registration {
    .auth-layout__content {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
    }

    .registration {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
    }

    .registration-content {
      padding: 0 0 109px;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;

      label:not(.link) {
        font-weight: 600;
        font-size: 12px;
      }

      .registration-bottom {
        border-top: 1px solid #e8e8e8;
        padding: 30px 50px;
      }

      .form {
        flex: 1 0 auto;
      }
    }
  }
}

@media (min-width: 992px) {
  .auth-layout {
    display: flex;
    align-items: center;

    &::before {
      content: none;
    }

    &__content::after {
      top: auto;
      bottom: 30px;
      margin-left: -44px;
    }

    &__mob-img {
      display: none;
    }

    &__img {
      display: block;
      border-radius: 26px;
      min-width: 0;
      flex: 1 1 0;
    }

    &__content {
      background: #fff;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
      border-radius: 26px;
      width: 635px;
      min-height: 655px;
      margin: 0 0 0 -100px;
      padding: 40px 50px;
    }
  }
}

@media (min-width: 1200px) {
  .auth-layout {
    &__content::after {
      content: none;
    }
  }
}
