.sh-text-block-title {
  font-weight: 600;
  font-size: 16px;
  color: #262626;
  margin-bottom: 8px;
}

.sh-text-block-body {
  font-weight: 500;
  font-size: 16px;
  color: #6a6a6a;

  p {
    margin-bottom: 8px;
  }
}
