.block {
  background: #ffffff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 40px 56px;
  }

  &--mh {
    padding-bottom: 1px;
    min-height: 300px;
    position: relative;
  }
}
