.partners-page {
}

.partners-slogan {
  margin-bottom: 80px;

  &__image {
    margin-bottom: -30px;

    img {
      width: 100%;
      height: auto;
      // border-radius: 20px; // uncomment if radius will get removed from the image
    }
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 40px;

    &__image {
      order: 2;

      img {
        max-width: 360px;
        width: auto;
        height: auto;
      }
    }
  }

  @media (min-width: 992px) {
    &__image {
      img {
        max-width: unset;
      }
    }
  }
}

.partners-list {
  &__title {
    text-align: center;
    margin-bottom: 40px;
  }
}

.partners-item {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-top: 2px solid #e3e3e3;
  border-bottom: 2px solid #e3e3e3;
  padding: 40px 0;

  &__images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    gap: 30px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__title {
    font-size: 20px;
    margin: 0 0 26px;
  }

  &__description {
    font-size: 16px;
    color: #6a6a6a;
  }

  &__link {
    font-size: 16px;
    margin-top: 24px;
    color: #6a6a6a;

    a {
      color: #00a0af;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__partners {
    margin-top: 36px;
  }

  & + & {
    margin-top: -2px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    &__images {
      flex: 0 0 390px;
      min-width: 0;
    }
  }
}

.partners-item-partners {
  &__title {
    font-size: 20px;
    color: #8a8a8a;
    margin: 0 0 10px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 50px;
    align-items: center;
  }
}
