/*******************************
         Site Overrides
*******************************/

.ui.basic.table.student-list {
  font-size: 16px;
  background: white;
}

.ui.basic.table.student-list.pending {
  padding: 13px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.ui.basic.table.student-list td {
  height: 78px;

  img {
    vertical-align: middle;
    margin-right: 10px;
  }
}

.ui.basic.table.student-list td.surname-letter {
  span {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    padding: 6px 9px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px
  }
}

.ui.basic.table.student-list td.student-list-actions {
  text-align: right;

  :first-child {
    margin-right: 10px;
  }

  button {
    display: inline;
  }

  &--multiple {
    a {
      margin: 0 10px;
    }
  }

  &--multiline {
    line-height: 1.5;
  }
}