/*******************************
         Site Overrides
*******************************/

.ui.checkbox.sh-filter label {
  font-size: 16px;
  line-height: 19px;
  color: @grey;
}

.sh-filter-count {
  color: #c7c7c7;
}

.ui.checkbox label::before,
.ui.checkbox label::after {
  top: -2px;
}
