.reports-page {
  .tabs {
    > .menu {
      flex-wrap: wrap;
      > .item {
        padding: 12px 0 !important;
        margin: 0 16px 0 0 !important;
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }
  }

  .list-filter {
    margin-bottom: 14px;
  }

  .page-title {
    max-width: none;
  }
}

.report {
  margin: 0 0 16px;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    margin: 0 0 14px;
    display: block;
  }

  &__counts {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 100%;

    li {
      min-width: 0;
      padding: 0 0 12px;
      margin: 0 0 25px;
      border-bottom: 2px solid #f4f4f4;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
    }

    strong {
      font-weight: 600;
      font-size: 44px;
      line-height: 51px;
      color: #00a0af;
      margin-right: 12px;
    }
  }

  &__top {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin: 0;
    padding: 0;
    list-style-position: inside;

    li {
      margin: 0 0 10px;
    }

    .badge {
      margin-left: 10px;
    }
  }

  &--top {
    margin-bottom: 40px;
    vertical-align: top;
  }

  &--shadowing {
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    &__counts {
      display: flex;
      flex-wrap: wrap;

      li {
        flex: 0 0 calc(50% - 15px);
        margin-right: 15px;

        &:nth-child(even) {
          margin-left: 15px;
          margin-right: 0;
        }
      }
    }

    &--top {
      display: inline-block;
      margin-right: 120px;
    }
  }

  @media (min-width: 992px) {
  }

  @media (min-width: 1200px) {
  }
}
