.footer {
  padding: 40px 34px 35px 34px;
  font-weight: 600;
  position: relative;
  z-index: 11;

  &__title {
    margin: 0 0 16px;

    a {
      color: @primaryColor;
    }
  }

  &__contacts {
    margin: 0 0 16px;
  }

  &__creators {
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;

    img {
      margin-left: 7px;
      width: 51px;
      height: 14px;
      vertical-align: top;
      margin-top: -1px;
    }
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr max-content;

    &__title,
    &__creators {
      grid-column: 1;
    }

    &__contacts {
      grid-column: 2;
      grid-row: 1 / span 3;
      margin: 0;
      align-self: end;
    }
  }
}
