/*******************************
         Site Overrides
*******************************/

@import "../../../styles/styles.scss";

body {
  letter-spacing: -0.05em;
}

p {
  line-height: 24px;
}