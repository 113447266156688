.link {
  color: #6a6a6a;
  border: 0 none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  background: transparent;

  &:active,
  &:hover {
    color: #00a0af;
  }
}
