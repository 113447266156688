.simple-pagination {
  text-align: center;

  button {
    margin: 0;
    border: 0 none;
    padding: 5px;
    font-size: 0;
    background: transparent;
    cursor: pointer;

    &::before {
      content: " ";
      width: 11px;
      height: 11px;
      display: block;
      background: rgba(#00a0af, 0.1);
      border-radius: 50%;
    }

    &:focus {
      outline: none;
    }

    &:focus::before {
      outline: 1px solid #00a0af;
    }

    &.active::before,
    &:hover::before {
      background-color: #00a0af;
    }
  }
}
