/*******************************
    User Variable Overrides
*******************************/

.ui.items>.item.shadowing-list-item {
    border: 1px solid #E3E3E3;
    background-color: #fff;
    border-radius: 4px;
    padding: 30px;

    .content>.header {
        font-weight: 600;
    }

    &--inactive {
      filter: grayscale(1);
      opacity: 0.5;
    }
}

.ui.items>.item .meta {
    font-weight: 600;

    :last-child {
        margin-right: 3px;
    }

    .popup-icon {
      display: inline-block;
      vertical-align: top;
      margin-top: -9px;
      margin-left: 5px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
}

.ui.items>.item.shadowing-list-item .extra>* {
    margin-top: 20px;
}

.ui.items>.item.shadowing-list-item .extra > .shadowing-item-extra__date {
    text-align: right;
    margin-bottom: -10px;

    span {
      color: #6A6A6A;
    }
}

.ui.items>.item .meta * {
    display: inline-block;
    margin-top: 3px;
}

.ui.items>.item.shadowing-list-item .image>img {
  max-height: 250px;
  object-fit: contain;
}