.company-list {
  .item:not(:first-child) {
    padding-top: 20px !important;
  }

  .item:not(:last-child) {
    padding-bottom: 20px !important;
  }

  .company-list__item-header {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;

    color: @black;
  }

  .ui.accordion .title:not(.ui) {
    font-weight: 400;
    padding-top: 0px;
    margin-top: 7px;
    font-size: 16px;
    line-height: 24px;
    color: @grey;
    text-decoration: underline;

    &:hover {
      color: @green;
    }
  }

  .ui.accordion .title .dropdown.icon {
    text-decoration: none;
  }
}

.company-list__item {
  box-shadow: none !important;
  border: 1px solid #e9e9e9 !important;

  .company-list__item-actions {
    margin-top: 20px;
    display: flex;
    float: left;

    button:not(:first-child) {
      margin-left: 10px !important;
    }
  }

  .company-list__item-details {
    color: @black;

    .company-list__item-details__header {
      color: @black;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 8px;
    }

    div:not(.company-list__item-details__header) {
      color: @grey;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .company-list__item-accordion .title {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.pending-company-registrations__body {
  margin-left: 16%;
  margin-right: 16%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pending-company-registrations__count {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.name {
  color: @black;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}
