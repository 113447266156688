.links {
  &__title {
    font-family: Spartan;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: #262626;
    margin: 0 0 20px;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 13px 28px 11px 18px;
    text-decoration: none;
    margin: 0 14px 14px 0;
    border: none;
    cursor: pointer;

    font-family: Spartan;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;

    color: #00a0af;

    span {
      text-decoration: underline;
      color: #6a6a6a;
      margin-left: 18px;
    }

    &::before {
      content: " ";
      background: url(../../icons/attachment.svg) center center no-repeat;
      width: 18px;
      height: 24px;
      float: left;
      margin: -1px 10px 0 0;
    }

    &:hover {
      text-decoration: none;

      span {
        text-decoration: none;
      }
    }
  }
}
