.radio-group {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    margin-top: 10px;
  }

  .ui.radio.checkbox label:before,
  .ui.radio.checkbox label:after {
    top: -2px;
  }
}
.points-group {
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 5px;
    overflow: hidden;
    position: relative;
  }

  label {
    display: block;
    background: #fff;
    color: #262626;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    font-family: Spartan;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 2;
    padding: 7px 6px 5px;
    min-width: 44px;
    height: 44px;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: #f6f6f6;
    }

    &:active {
      background-color: #eee;
    }
  }

  input {
    position: absolute;
    left: -20px;
  }

  input:focus-visible + label {
    outline: 1px solid #00a0af;
    outline-offset: -3px;
    border-radius: 4px;
  }

  input:disabled + label {
    opacity: 0.45;
    background: #fff;
    color: #262626;
    cursor: default;
  }

  input:checked + label {
    color: #fff;
    background-color: #00a0af;
    border-color: #00a0af;
  }

  input:checked:focus-visible + label {
    outline-color: #fff;
    outline-offset: -2px;
  }
}

.input-preview {
  .input {
    display: none !important;
  }
  p {
    margin: 0;
  }
}

.filter-checkbox-group {
  .ui.checkbox {
    display: block !important;
    margin-bottom: 10px !important;
  }
}

.ui.form .ui.divider + .ui.grid {
  margin-top: 20px;
}

.ui.dropdown.search input.search {
  height: 100%;
}
