.homepage-video-slider {
  margin: 0 0 64px;
}

.video-slide {
  @gap: 52px;
  padding: 0 (@gap / 2);
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  display: block;
  text-decoration: none;

  &__image {
    position: relative;
    margin: 0 0 26px;

    img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }

    &::before {
      content: url(../../../icons/play.svg);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    &::after {
      content: " ";
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      inset: 0;
      border-radius: 10px;
      box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .article__title-sm {
    margin-bottom: 16px;
  }

  @media (hover: hover) {
    &:hover {
      color: #6a6a6a;
      text-decoration: none;
      .video-slide__image {
        &::after {
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}
