.badge {
  background: #e3fdff;
  border-radius: 4px;
  color: #00a0af;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  padding: 8px 6px 4px;
  display: inline-block;
  white-space: nowrap;

  img {
    vertical-align: top;
    margin: -2px 5px 0 0;
  }
}
