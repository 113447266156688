/*******************************
         Site Overrides
*******************************/

.ui.grid>.row>.column.welcome-photo-left {
  margin-top: 57px;
  padding-right: 0px;
}

.ui.grid>.row>.column.welcome-photo-right {
  margin-top: 87px;
  padding-left: 0px;
}

.ui.grid > .row {
    padding-top: .75rem;
    padding-bottom: .75rem;
}
