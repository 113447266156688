.homepage-article-list {
  margin-bottom: 40px;

  .ui.divider + & {
    margin-top: 90px;
  }

  .article {
    &__title {
      margin-bottom: 50px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -26px;
    }

    &__item {
      flex: 0 0 100%;
      padding: 0 26px;
      margin: 0 0 50px;
      min-width: 0;
      position: relative;
      z-index: 2;
      text-align: justify;

      @media (min-width: 600px) {
        flex-basis: 100% / 2;
      }

      @media (min-width: 992px) {
        flex-basis: 100% / 3;
      }
    }

    &__title-sm {
      margin-bottom: 22px;
      b {
        color: #00a0af;
        font-weight: inherit;
      }
    }

    &__image {
      margin: 0 0 28px;
      width: 100%;
      border-radius: 30px;
    }
  }
}
