/*******************************
         Site Overrides
*******************************/

.ui.button {
  font-weight: 600;
  padding: 15px 22px 15px 22px;
  font-size: 14px;
  padding: 15px 22px;
  height: 50px;
}

.ui.basic.button {
  font-weight: 600;
  font-size: 14px;
}

a.ui.button {
  line-height: 20px;
}

.ui.negative.button {
  border: 1px solid @softRed;
}

