@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slider {
  @gap: 52px;
  margin: 0 (@gap / -2);
  padding: 0 0 76px;

  .slick-arrow {
    position: absolute;
    inset: auto;
    bottom: -100px;
    right: (@gap / 2);
    left: auto;
    top: auto;
    width: 50px;
    height: 50px;
    background: #fff !important;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    opacity: 1;
    font-size: 0;
    overflow: hidden;
    border: 0 none;
    outline: 0 none;
    z-index: 3;
    cursor: pointer;

    &::before {
      content: " ";
      display: block;
      background: url(../../icons/arrow-right-green.svg);
      height: 11px;
      width: 8px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -6px 0 0 -4px;
      opacity: 1 !important;
    }

    &:hover {
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
    }
  }

  .slick-disabled {
    opacity: 0.25;
    cursor: default;
    pointer-events: none;
  }

  .slick-prev {
    right: 50px + 16px + (@gap / 2);

    &::before {
      transform: rotate(180deg);
    }
  }
}
