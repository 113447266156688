/*******************************
         Site Overrides
*******************************/


.ui.input.position-search {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 100%;
}

.ui.input.position-search>input {
  border: none;
  height: 48px;
}

.item-filter .ui.input.position-search>input {
  border: none;
  height: 32px;
}

.ui.icon.pass {
  > i.icon {
    pointer-events: auto !important;
    opacity: 1;
  }
  .icon {
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
