/*******************************
    User Variable Overrides
*******************************/

/*--------------
    Applications
---------------*/

.application.ui.cards > .card {
  margin: 0.875em 0.875em;
  border: 1px solid #e3e3e3;
}

.application.ui.cards > .card .meta,
.ui.card .meta {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 600;
  line-height: 13.44px;
  color: @primaryColor;

  :last-child {
    margin-right: 3px;
  }
}

.application.ui.cards > .card .meta *,
.ui.card .meta * {
  margin-right: 26px;
  margin-bottom: 6px;
}

.application.ui.cards > .card > .content,
.ui.card > .content {
  border-top: none;
  padding: 26px 26px 26px 26px;
}

.application.ui.cards > .card,
.ui.card {
  box-shadow: none;
}

.application.ui.cards > .card > .image,
.ui.card > .image {
  background: none;
}

.application.ui.cards > .card > .image:not(.ui) > img,
.ui.card > .image:not(.ui) > img {
  border: none;
}

.application.ui.cards > .card > .content > .header:not(.ui),
.ui.card > .content > .header:not(.ui) {
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
}

.application.ui.cards > .card > .content > .description,
.ui.card > .content > .description {
  color: #6a6a6a;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 20px;
}

.application.ui.cards > .card > .content > .description :not(:last-child) {
  margin-right: 15px;
}

/*--------------
    Registration
---------------*/

.ui.card.registration {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  width: 635px;
  height: 655px;
}

.registration.ui.cards > .card > .content > .header:not(.ui),
.ui.card > .content > .header:not(.ui) {
  font-weight: 600;
  font-size: 44px;
  line-height: 51px;
}
