.homepage-article-slider {
  margin: 100px 0 64px;

  .article__title {
    margin-bottom: 26px;
  }

  .quick-tabs {
    margin-bottom: 60px;
  }
}

.article-slide {
  padding: 0 26px;

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: justify;

  &__image {
    height: 60px;
    margin: 0 0 16px;

    img {
      height: 100%;
    }
  }

  .teacher-slide &__image,
  .student-slide &__image {
    width: 60px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &__header {
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  &__title {
    margin: 0 0 10px;
  }
}

.quick-tabs {
  display: flex;
  gap: 10px;

  button {
    font-family: Spartan;
    border: 1px solid #3e3e3e;
    font-size: 14px;
    line-height: 16px;
    padding: 7px 18px 5px;
    background: transparent;
    border-radius: 16px;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      color: #00a0af;
      border-color: #00a0af;
    }

    &.active {
      color: #00a0af;
      border-color: #00a0af;
      background-color: #e5f5f7;
    }
  }
}
