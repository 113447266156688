.page-title {
  margin-top: 50px;
  margin-bottom: 41px;

  .title {
    font-weight: 600;
    font-size: 44px;
    margin-bottom: 0;

    em {
      font-size: 64px;
      line-height: 80px;
      display: block;
      font-style: normal;
    }
  }

  .description {
    color: #6a6a6a;
    font-weight: 500;
    font-size: 16px;
    margin-top: 12px;
  }
}

.page-title.extra-top-padding {
  padding-top: 80px;
}
