/*******************************
         Site Overrides
*******************************/

.ui.secondary.menu .item {
  background: transparent;
}

@textMenuActiveItemColor: #00A0AF;
@textMenuActiveItemFontWeight: 500;

@textMenuItemColor: #262626;
@textMenuItemPadding: 10px;
@textMenuItemFontWeight: 500;

@textMenuMargin: 0px;

/*--------------
    Text Menu
---------------*/

.ui.text.menu {
  background: none transparent;
  border-radius: 0px;
  box-shadow: none;
  border-bottom: 1px solid #E3E3E3;
  margin: @textMenuMargin;
}

.ui.text.menu .item {
  border-radius: 0px;
  box-shadow: none;
  align-self: center;
  margin: @textMenuItemMargin;
  padding: @textMenuItemPadding;
  color: @textMenuItemColor;
  transition: @textMenuItemTransition;
  font-weight: @textMenuItemFontWeight;
}

/*--- active ---*/
.ui.text.menu .active.item {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-weight: @textMenuActiveItemFontWeight;
  color: @textMenuActiveItemColor;
}

/*--------------
   Pagination
---------------*/

.ui.secondary.menu {
  background: @secondaryBackground;
  margin-left: -@secondaryItemSpacing;
  margin-right: -@secondaryItemSpacing;
  border-radius: 0em;
  border: none;
  box-shadow: none;
}

.ui.secondary.menu .item {
  outline: none;
  font-weight: 600;
  color: @primaryColor;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}

/* Active */
.ui.secondary.menu .active.item {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}

/* Active Hover */
.ui.secondary.menu .active.item:hover {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
}

.ui.pagination.menu .item:last-child {
  border-radius: @secondaryItemBorderRadius;
}
.ui.pagination.menu .item {
  justify-content: center;
}

/*--------------
   REGISTRATION TYPE SELECT
---------------*/

.ui.vertical.registration-menu.menu {
  background: white;
  border: none;
  margin-left: 0;
  margin-right: 0;
}

.ui.vertical.registration-menu.menu .active.item {
  background: #01A0AC;
  border-radius: 10px;
  border-color: transparent;
  color: white;
}

.ui.vertical.registration-menu.menu .item {
  padding: 20px;
  border-radius: 10px !important;
  color: #262626;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
}
