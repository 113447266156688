.icon.sh-icon-search {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  margin: auto;
}

.icon.sh-icon-hidden {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 5px;
  margin: auto;
}
