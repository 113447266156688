.school-list {
  margin: -20px 0 !important;

  .item {
    position: relative;
    padding-top: 27px !important;
    padding-bottom: 25px !important;
  }

  .header {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    margin-right: 100px !important;

    color: @black;
  }

  .ui.accordion .title:not(.ui) {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 400;
    padding-top: 0px;
    margin-top: 25px;
    font-size: 16px;
    line-height: 24px;
    color: @grey;
    text-decoration: underline;

    &:hover {
      color: @green;
    }
  }

  .ui.accordion .title .dropdown.icon {
    text-decoration: none;
  }
}

.school-list__item-details {
  color: @black;

  .school-list__item-details__header {
    color: @black;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  div:not(.school-list__item-details__header) {
    color: @grey;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .school-list__item-details-actions {
    a:not(:first-child) {
      margin-left: 20px;
    }
  }
}
