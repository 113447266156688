.student-application-item {
  .row {
    border-bottom: 1px solid #dededf;
    padding-bottom: 20px !important;
    margin-bottom: 10px !important;
  }

  .name {
    color: @black;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .details {
    color: @primaryColor;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 10px;

    span:not(:last-child) {
      margin-right: 30px;
    }

    span {
      display: inline-block;
      margin-bottom: 6px;
    }

    img {
      margin-right: 6px;
    }
  }

  .motivation-letter {
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 16px;
    color: @grey;
    p {
      margin: 0;
    }
  }

  .student-application-actions {
    font-size: 16px;
    margin-bottom: 15px;

    :not(:last-child) {
      margin-right: 24px;
    }
  }
}

.student-application-item__logo {
  margin-bottom: 15px;

  img {
    height: 80px;
    max-width: 100%;
  }
}
