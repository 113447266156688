.page-photos-five {
  display: flex;
  margin-top: 43px;
  text-align: right;

  div:first-child {
    margin-left: 0px;
  }

  div {
    margin-left: 14px;
    margin-right: 0px;
  }

  div:nth-child(odd) {
    margin-top: 42px;
  }

  img:not(:first-child) {
    margin-top: 14px;
  }
}
