/*******************************
        User Overrides
*******************************/


.ui.secondary.menu .item {
    color: @black;
    box-shadow: none;
}

.ui.secondary.pointing.menu .active.item {
    color: @primaryColor;
    border-bottom: 1px solid @primaryColor;
    padding-left: 0;
    padding-right: 0;

    &:hover {
        box-shadow: none;
        color: @primaryColor;
        border-bottom: 1px solid @primaryColor;
    }
}

.ui.secondary.pointing.menu {
    border-bottom: none;
}