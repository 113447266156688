.homepage-faq {
  position: relative;
  margin: 25px 0 0;
  padding: 102px 0 50px;

  @media (min-width: 992px) {
    padding: 180px 0 80px;
  }

  .article__title {
    margin-bottom: 26px;
  }

  .quick-tabs {
    margin-bottom: 40px;
  }

  &::before {
    content: " ";
    width: 100vw;
    margin-left: -50vw;
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
  }

  .homepage-faq-wrapper {
    position: relative;
    z-index: 2;
  }
}

.faq-item {
  border-bottom: 1px solid #d1d1d1;
  margin: 0 0 10px;

  &__title {
    font-family: "Spartan", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0;
    color: #262626;
    padding: 10px 0;

    border: 0 none;
    background: transparent;
    display: flex;
    width: 100%;
    cursor: pointer;
    text-align: left;

    .icon {
      margin-left: auto;
      width: 16px;
    }
  }

  &__content {
    height: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
  }

  &.visible &__content {
    height: auto;
    opacity: 1;
    transition: opacity 0.3s ease;
    padding-bottom: 20px;
  }
}
