.homepage-promo {
  position: relative;
  margin-top: -100px;
  padding: 100px 0 152px;

  @media (min-width: 768px) {
    min-height: 823px;
  }

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -50vw;
    width: 100vw;
    height: 100%;
  }

  .article {
    color: #fff;

    &__content {
      position: relative;
      z-index: 2;
      padding: 32px 0 80px;

      @media (min-width: 768px) {
        padding: 54px;
      }
    }

    &__body {
      max-width: 650px;
    }

    &__pretitle {
      color: #fff;

      &::before {
        background-color: #fff;
      }
    }

    &__title {
      color: #fff;
    }
  }
}
