.company-details-positions-count {
  h4 {
    display: inline;
  }

  span {
    color: #c7c7c7;
  }
}

.ui.basic.table.company-details td {
  vertical-align: top;
  border-right: none;
}

.ui.basic.table.company-details td:nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  color: @grey;
  text-align: right;
}

.ui.basic.table.company-details tr:first-child {
  td {
    padding-top: 0px;
  }
}

.company-details-bg {
  margin-top: -30px;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  img {
    width: 100%;
  }
}

.company-details-logo {
  position: absolute;
  width: 190px;
  height: 60px;
  margin-top: -34px;
  background-color: white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
