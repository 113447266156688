/*******************************
        Site Overrides
*******************************/
.ui.message {
  font-size: 16px;
  line-height: 20px;
  padding: 21px 20px 19px;

  .icon {
    vertical-align: top;
    width: 24px;
    height: 24px;
    margin: -2px 14px -2px 0;
    display: inline-block;
  }

  &.warning {
    background-color: #fff5e9;
    color: #262626;
    border: 0 none;
    box-shadow: none;
  }

  .message-header {
    display: block;
    margin-bottom: 8px;
  }
}
