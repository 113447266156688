.student {
  &__item {
    border-bottom: 1px solid #dededf;
    padding-bottom: 20px !important;
    margin-bottom: 10px !important;
  }

  &__name {
    color: @black;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }
}
