.page-homepage {
  background: transparent;

  &::before,
  &::after {
    display: none;
  }

  .ui.top.menu {
    transition: all 0.5s ease;

    &:not(.is-scrolled) {
      box-shadow: 0 1px 1px rgba(277, 277, 277, 0.2);
      background-color: transparent;

      .item {
        opacity: 1;
        transition: none;
        color: rgba(255, 255, 255, 0.5);

        &.active,
        &:hover {
          color: #fff;
        }
      }

      .logo {
        background: url(../../../public/assets/logo-white.png) center center no-repeat;
        background-size: 214.667px auto;
        img {
          opacity: 0;
        }
      }

      .dropdown {
        border-color: #fff !important;
        color: #fff;

        &.active,
        &:hover {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }

  .ui.divider {
    border-color: rgba(0, 0, 0, 0.15) !important;
    border-bottom: 0 none !important;
    border-width: 2px !important;
  }

  .footer {
    background: #efefef;
    position: relative;
    z-index: 11;
  }
}

.article {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #6a6a6a;

  a {
    color: #00a0af;
  }

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    display: block;
    color: #262626;
    margin: 0 0 36px;

    @media (min-width: 768px) {
      font-size: 44px;
      line-height: 51px;
    }
  }

  &__pretitle {
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    display: block;
    padding-left: 36px;
    position: relative;
    color: #262626;
    margin: 0 0 30px;

    &::before {
      content: " ";
      left: 0;
      top: 10px;
      height: 1px;
      width: 23px;
      background-color: #262626;
      position: absolute;
    }
  }

  &__title-sm {
    font-size: 22px;
    line-height: 36px;
    font-weight: 600;
    display: block;
    margin: 0 0 30px;
    color: #262626;
  }

  &__actions {
    margin: 30px 0 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;

    &::before {
      content: " ";
      width: 3px;
      height: 3px;
      position: absolute;
      left: 0;
      top: 8px;
      background-color: #00a0af;
      border-radius: 50%;
    }
  }
}

.homepage-promo::before {
  background: url(../../../public/assets/homepage-sm.jpg) center 0 no-repeat #000;

  @media (min-width: 768px) {
    background-image: url(../../../public/assets/homepage.jpg);
  }
}

.homepage-faq::before {
  background: url(../../../public/assets/bottom-bg.png) center top no-repeat;
}
