.profile-data-table {
  word-wrap: break-word;

  .row {
    img {
      margin-right: 10px;
    }

    h3 {
      display: inline;
      vertical-align: text-bottom;
    }

    .column div:last-child {
      color: @grey;
      font-weight: 500;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.profile-data-table-divider {
  margin-left: 0px !important;
}

.profile-data-table-description {
  p {
    color: @grey;
    font-weight: 500;
    font-size: 16px;
  }
}

.profile-data-company-logo-wrapper {
  margin-bottom: 20px;

  img {
    max-height: 60px;
  }
}

.profile-form-actions {
  margin-top: 34px;
}

.data-row-wrapper {
  display: flex;
  justify-content: space-between;

  > div:first-child {
    margin-right: 5px;
  }
}
