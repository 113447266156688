@import "~react-datepicker/dist/react-datepicker.css";

@mainColor: #626366;
@titleColor: #6a6a6a;
@lightColor: #c5c5c5;
@highlightColor: #00a0af;
@inactiveColor: #efefef;
@weekendColor: #f3ac16;

@borderColor: #efefef;

@hoverBg: #f6f6f6;
@selectedBg: @highlightColor;

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 6px;
}

.itemColors() {
  color: @mainColor !important;
  &:hover {
    color: @mainColor !important;
    background-color: @hoverBg !important;
  }
  &:active {
    background-color: darken(@hoverBg, 3%) !important;
  }
}

.selectedItemColors() {
  color: #fff !important;
  background-color: @selectedBg !important;
  &:hover {
    color: #fff !important;
    background-color: lighten(@selectedBg, 3%) !important;
  }
  &:active {
    background-color: darken(@selectedBg, 3%) !important;
  }
}

.react-datepicker {
  font-family: "Spartan", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  background: #fff;
  border: 2px solid @borderColor;
  border-radius: 6px;
  display: flex;
  user-select: none;
  width: 260px;

  &-wrapper {
    display: block;
  }

  &__triangle {
    display: none;
  }

  &__month-container {
    padding: 13px 0 8px;
    // min-height: 271px;
  }

  &__navigation {
    top: 17px;
    outline: 0 none;
  }

  &__header {
    background: transparent;
    border: 0 none;
    padding: 0;
  }

  &__current-month {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: @titleColor;
    margin: 0 0 15px;
  }

  &__day-names {
    line-height: 0;
    position: relative;
    margin-bottom: 3px;

    &:after {
      content: " ";
      position: absolute;
      left: 24px;
      right: 24px;
      bottom: -1px;
      border-bottom: 1px dashed @borderColor;
    }
  }

  &__day-name {
    color: @lightColor;
    width: 28px;
    height: 28px;
    line-height: 32px;
    margin: 0 2px;
    overflow: hidden;
  }

  &__month {
    margin: 0 14px;
  }

  &__week {
    line-height: 0;
    margin: 0 0 2px;
  }

  &__day {
    outline: 0 none;
    width: 28px;
    height: 28px;
    line-height: 32px;
    margin: 0 2px;
    border-radius: 50%;
    overflow: hidden;

    .itemColors();

    &:hover {
      border-radius: 50%;
    }

    &--today {
      color: @highlightColor !important;
    }

    &--outside-month {
      color: @inactiveColor !important;
    }

    &--weekend {
      color: @weekendColor !important;

      &.react-datepicker__day--outside-month {
        color: lighten(@weekendColor, 35%) !important;
      }

      &:hover {
        color: @weekendColor !important;
      }
    }

    &--keyboard-selected {
      background-color: @hoverBg !important;
    }

    &--in-selecting-range,
    &--in-range {
      color: @mainColor !important;
      background-color: @hoverBg !important;
    }

    &--range-start,
    &--range-end,
    &--selected {
      .selectedItemColors();
    }
  }

  &__time-container {
    border-left: 1px dashed @borderColor;
    padding: 13px 0 0;
  }

  &-time__header {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    color: @titleColor;
    padding-bottom: 10px;
    border-bottom: 1px dashed @borderColor;
  }

  &__time-list {
    outline: 0 none;
  }

  &__time-container &__time &__time-box ul&__time-list li&__time-list-item {
    outline: 0 none;
    height: auto;
    padding: 9px 5px 7px;

    .itemColors();

    &--selected {
      .selectedItemColors();
    }
  }

  &__custom-header {
    margin: 5px 10px 10px;
    display: flex;
    justify-content: space-between;

    select {
      font-family: "Spartan", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      border-radius: 3px;

      width: auto !important;
      background: transparent !important;
      color: #6a6a6a !important;
      appearance: none !important;
      border: 1px solid #efefef !important;
      padding: 5px !important;
      margin: 0 2px !important;
      text-align: center !important;

      &:hover,
      &:focus {
        outline: none;
        border-color: #a6a6a6 !important;
      }
    }

    option {
      font-size: 14px;
      font-weight: 400;
    }

    button {
      font-size: 0;
      background: transparent;
      border: 1px solid transparent;
      overflow: hidden;
      position: relative;
      padding: 0;
      flex: 0 0 32px;
      padding-left: 30px;
      width: 30px;
      height: 32px;
      margin: 0;
      border-radius: 5px;

      &::before {
        content: " ";
        border: 10px solid transparent;
        border-left-color: #ccc;
        left: 50%;
        top: 50%;
        margin-top: -10px;
        margin-left: -5px;
        position: absolute;
      }

      &:focus {
        border-color: #e8e8e8;
        outline: none;
      }

      &:hover {
        &::before {
          border-left-color: #a6a6a6;
        }
      }

      &:first-child {
        &::before {
          border-left-color: transparent;
          border-right-color: #ccc;
          margin-left: -15px;
        }

        &:hover {
          &::before {
            border-left-color: transparent;
            border-right-color: #a6a6a6;
          }
        }
      }
    }
  }
}

.react-timepicker,
.react-datetimepicker {
  width: auto;
}
