.welcome-photos {
  padding-top: 6.2%;
  margin-bottom: -70px;
  overflow: hidden;

  &__img1,
  &__img2,
  &__img3 {
    border-radius: 30px;
    display: block;
  }

  &__img1 {
    margin-top: -6.2%;
    width: 61.8%;
    float: left;
  }

  &__img2 {
    width: 34%;
    float: right;
    margin-bottom: 4.1%;
  }

  &__img3 {
    width: 34%;
    float: right;
  }

  @media (min-width: 768px) {
    max-width: 485px;
    margin-bottom: 0;
    float: right;
  }
}
