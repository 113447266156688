/*******************************
         Site Overrides
*******************************/

.ui.form .field>label {
  font-weight: 600;
  font-size: 12px;
}

.ui.form .field {
  margin-bottom: 25px;

  .popup-icon {
    display: inline-block;
    vertical-align: top;
    margin-left: 8px;
    margin-top: -3px;
  }
}

.ui.form input:not([type]), .ui.form input[type="date"], .ui.form input[type="datetime-local"], .ui.form input[type="email"], .ui.form input[type="number"], .ui.form input[type="password"], .ui.form input[type="search"], .ui.form input[type="tel"], .ui.form input[type="time"], .ui.form input[type="text"], .ui.form input[type="file"], .ui.form input[type="url"] {
  line-height: 2;
  padding: 11px 14px 9px;
}

.ui.form textarea {
  font-family: 'Spartan', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 15px 14px;
  line-height: 1.5;
}

.ui.form .ui.grid {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.ui.form .ui.grid .column {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.ui.form .fields.error .field .ui.dropdown .text,
.ui.form .field.error .ui.dropdown .text {
    background: transparent;
}