.preview-item {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 15px;

  & + & {
    margin-top: 12px;
  }

  &__image {
    margin: 0 0 10px;
  }

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    margin: 0 0 4px;
    display: block;
  }

  .shadowing-metadata {
    display: flex;
    margin-bottom: 0;

    span:nth-child(3) {
      order: -1;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    align-items: center;

    &__image {
      flex: 0 0 auto;
      margin: 0 20px 0 0;
    }
  }
}
